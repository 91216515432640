import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Typography,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React from 'react';

/* eslint-disable @typescript-eslint/no-explicit-any */
export default function Insurance(): any {
  const [checked, setChecked] = React.useState(false);
  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };
  return (
    <React.Fragment>
      <br />
      <Typography variant="h5" gutterBottom>
        Insured Details
      </Typography>
      <Card variant="outlined">
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              Address....
            </Grid>
            <Grid item xs={12}>
              &nbsp;
            </Grid>
            <Grid item xs={11}>
              We have this person on fle to pay for costs not covered by insurance. Is this information correct?
            </Grid>
            <Grid item xs={1}>
              <FormControlLabel
                id="info-correct"
                control={<Switch checked={checked} onChange={toggleChecked} />}
                label="Normal"
              />
            </Grid>
            <Grid item xs={12}>
              &nbsp;
            </Grid>
            <Grid item xs={11}>
              Would you like to use insurance to pay for this appointment?
            </Grid>
            <Grid item xs={1}>
              <FormControlLabel
                id="use-insurance"
                control={<Switch checked={checked} onChange={toggleChecked} />}
                label="Normal"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <br />
      <Typography variant="h5" gutterBottom>
        Insurance on File
      </Typography>
      <Card className="cardBlock" variant="outlined">
        <CardContent>
          <Grid container spacing={1} className="details">
            <Grid item xs={12}>
              <Typography variant="h6">Anthem Medicare / Anthem Advtg. Mediblue</Typography>
            </Grid>
            <Grid item xs={6}>
              <label>Subscriber Name</label> <br />
              <b>LastName, FirstName</b>
            </Grid>
            <Grid item xs={6}>
              <label>Subscriber Number</label>
              <br />
              <b>xxxxxxxxxxx</b>
            </Grid>
            <Grid item xs={6}>
              <label>Group Number</label> <br />
              <b>xxxxx</b>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className="card-footerbg-primary">
          <Grid container>
            <Grid item xs={12}>
              <Box display="flex" alignContent="center" className="fullWidth">
                <IconButton aria-label="personalinfo-edit">
                  <FontAwesomeIcon icon={['fas', 'pencil-alt']} size="xs" />
                </IconButton>
                <Button id="update-coverage" size="small" fullWidth className="iconFadeOut btn-right-border">
                  Update Coverage
                </Button>
                <IconButton aria-label="personalinfo-edit">
                  <FontAwesomeIcon icon={['fas', 'times']} size="xs" />
                </IconButton>
                <Button id="remove-coverage" size="small" fullWidth>
                  Remove Coverage
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignContent="center" className="fullWidth">
                <IconButton aria-label="add-insurance-card-photos" className="iconFadeOut btn-top-border">
                  <FontAwesomeIcon icon={['far', 'address-card']} size="xs" />
                </IconButton>
                <Button id="add-ins-photos" size="small" fullWidth className="iconFadeOut btn-top-border">
                  Add insurance card photos
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      <Box display="flex" alignContent="center" className="card-footerbg-primary primaryBg">
        <IconButton color="primary" aria-label="add-insurance-card-photos">
          <Add />
        </IconButton>
        <Button variant="contained" id="add-new-ins" size="small" fullWidth color="primary">
          Add New Insurance Coverage
        </Button>
      </Box>

      <Box className="text-right">
        <FormControlLabel
          id="ins-info-correct"
          control={<Checkbox color="primary" name="saveInfo" value="yes" />}
          label="This information is correct"
        />
      </Box>
    </React.Fragment>
  );
}
