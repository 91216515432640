import { createStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createTheme';
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const componentStyles = (theme: Theme) =>
  createStyles({
    wrapper: {
      height: '100%',
      minHeight: '100%',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      display: 'flex',
      maxHeight: 100,
      zIndex: 2,
    },
    pageTitle: { margin: '0 0 0 5%' },
    sidebar: {},
    footer: {
      background: '$colorWhite',
      maxHeight: 100,
      width: '100vw',
      position: 'fixed',
      bottom: 0,
      borderTop: '4px solid #2c3376',
      zIndex: 3,
    },
    ApptTypeIcon: { color: theme.palette.secondary.main, height: 100, width: 100 },
    HospitalIcon: { color: '#7485a0', height: 100, width: 100 },
    fullWidth: { width: '100%' },
    margin0: { margin: 0 },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    teamCard: {
      maxWidth: 125,
      margin: 3,
      textAlign: 'center',
    },
    cardMedia: {
      height: 50,
      textAlign: 'center',
      color: '#748196',
    },
    specialization: { margin: 0, color: theme.palette.text.secondary },
    iconBox: {
      width: 80,
      height: 80,
      margin: 10,
      textAlign: 'center',
      '& img': {
        border: '1px solid #ddd',
        borderRadius: 3,
        padding: 3,
      },
      '& h5': {
        color: theme.palette.text.primary,
        margin: 0,
      },
    },
    cardBlock: {
      borderLeft: '3px solid #2c3376',
      margin: '10px 0',
    },
    cardBlockBg: {
      backgroundcolor: 'rgba(23, 43, 77, 0.07)',
    },
    floatR: { float: 'right' },
    InsPhoto: { border: '1px solid #ddd', height: 100 },
    padL15: { paddingLeft: theme.spacing(1) },
    padL2: { paddingLeft: theme.spacing(2) },
    pad1: { padding: theme.spacing(1) },
    pad3: { padding: theme.spacing(3) },
    paperScroll: {
      height: 450,
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    visit_date: {
      textAlign: 'center',
      padding: theme.spacing(0, 1),
      '& h1, & h2, & h3': {
        margin: 0,
      },
      '& h2': { color: theme.palette.secondary.main },
    },
    visit_day: {
      color: theme.palette.secondary.main,
    },
    infoMain: { color: theme.palette.info.main },
    visit_info: {
      paddingLeft: 10,
      '& h4, & p': {
        margin: 0,
      },
      '& p': { color: theme.palette.info.main },
      '& h4': { color: theme.palette.primary },
    },
    visit_title: {
      color: theme.palette.secondary.main,
    },
    marginT10: { marginTop: theme.spacing(1) },
    textCenter: { textAlign: 'center' },
    sticky: {
      position: 'sticky',
      bottom: 0,
    },
    QuestionsBlock: {
      marginBottom: theme.spacing(2),
    },
    Question: {
      '& span': { color: 'red' },
      marginBottom: theme.spacing(2),
    },
    marB1: { marginBottom: theme.spacing(1) },
    marB2: { marginBottom: theme.spacing(2) },
    marR2: { marginRight: theme.spacing(2) },
    timeBtn: { margin: theme.spacing(0, 2, 1, 0), width: 100 },
  });
export default componentStyles;
